import { createStore } from "vuex";
import axios from "axios";

let api_url = "https://api.tutoruu.com/api";

export default createStore({
  state: {
    universities: [],
    previewed_university: null,
    user: null,
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setPreviewedUniversity(state, payload) {
      state.previewed_university = payload;
    },
    addUniversityClasses(state, payload) {
      state.previewed_university.classes = payload;
    },
    addUniversityTutors(state, payload) {
      state.previewed_university.tutors = payload;
    },
    addUniversityTutor(state, payload) {
      state.previewed_university.tutors.push(payload);
      state.previewed_university.applications = state.previewed_university.applications.filter(
        (application) => application.uid._id != payload.user
      );
    },
    replaceApplication(state, payload) {
      for (let i = 0; i < state.previewed_university.applications.length; i++) {
        if (
          state.previewed_university.applications[i].uid._id == payload.uid._id
        ) {
          state.previewed_university.applications[i] = payload;
          return;
        }
      }
    },
    addUniversityTutorApplications(state, payload) {
      state.previewed_university.applications = payload;
    },
    addUniversities(state, payload) {
      state.universities = payload;
    },
    addClass(state, payload) {
      state.previewed_university.classes.push(payload);
    },
    addUniversity(state, payload) {
      state.universities.push(payload);
    },
  },
  actions: {
    async get_universities({ commit }) {
      await axios.get(`${api_url}/university`).then((data) => {
        commit("addUniversities", data.data);
      });
    },
    async get_university_classes({ state, commit }) {
      await axios
        .get(`${api_url}/university/${state.previewed_university._id}/classes`)
        .then((data) => {
          commit("addUniversityClasses", data.data);
        });
    },
    async get_university_tutors({ state, commit }) {
      await axios
        .get(`${api_url}/university/${state.previewed_university._id}/tutors`)
        .then((data) => {
          commit("addUniversityTutors", data.data.tutors);
        });
    },
    async get_university_tutor_applications({ state, commit }) {
      await axios
        .get(
          `${api_url}/university/${state.previewed_university._id}/tutor_applications`
        )
        .then((data) => {
          commit("addUniversityTutorApplications", data.data.applications);
        });
    },
    async accept_tutor({ commit }, applicant) {
      await axios
        .post(`${api_url}/tutor`, {
          application_id: applicant._id,
          user_id: applicant.uid,
        })
        .then((data) => {
          commit("addUniversityTutor", data.data);
        });
    },
    async update_application_classes({ commit }, payload) {
      await axios
        .patch(`${api_url}/user/${payload.user}/update_application`, {
          classes: payload.classes,
        })
        .then((data) => commit("replaceApplication", data.data));
    },

    async create_class({ state, commit }, payload) {
      await axios
        .post(
          `${api_url}/university/${state.previewed_university._id}/create_class`,
          { name: payload.name, desc: payload.desc }
        )
        .then((data) => {
          commit("addClass", data.data);
        });
    },
    async create_university({ commit }, payload) {
      await axios
        .post(`${api_url}/university`, {
          name: payload.name,
          abbrev: payload.abbrev,
          country: payload.country,
          locations: [],
        })
        .then((data) => {
          commit("addUniversity", data.data);
        });
    },
  },
  modules: {},
});
